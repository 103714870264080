const acolumns = [
    {
      title: 'No',
      slots: { customRender: 'no' },
    },
    {
      title: 'ID Penukaran',
      dataIndex: 'id',
    },
    {
      title: 'Tanggal Penukaran',
      dataIndex: 'tgl_penukaran',
    },
    {
        title: 'Nama Program',
        dataIndex: 'nama_program',
    },
    {
        title: 'Region Program',
        dataIndex: 'region',
    },
    {
      title: 'Reward',
      dataIndex: 'reward',
    },
    {
        title: 'Kode Toko',
        dataIndex: 'kode_toko',
      },
      {
        title: 'Nama Toko',
        dataIndex: 'name',
      },
    {
      title: 'Alamat Toko',
      dataIndex: 'address',
    },
    {
      title: 'Nominal Penukaran',
      dataIndex: 'nominal',
    },
    {
      title: 'Status Penukaran',
      dataIndex: 'status',
    },
    {
        title: 'Action',
        slots: { customRender: 'action' },
    },
  ]
  export default acolumns
  